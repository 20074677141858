import React from 'react';

const BasketCount = ({
  className = '',
  onClick = () => {},
  count = 0,
  text,
  isViewOrderDisabled = false,
}) => {
  const disabled = count < 1 || isViewOrderDisabled;
  return (
    <button
      data-test="basket-count-button"
      className={`text-2xl text-black bg-white uppercase border-4 border-black mx-4 flex flex-row items-center disabled:opacity-50 ${className}`}
      onClick={onClick}
      id="cart-icon"
      disabled={disabled}
    >
      <div className="text-black font-bold p-4">{text}</div>
      <div data-test="basket-count" className="text-3xl font-bold border-l-4 border-black p-4">
        {count || 0}
      </div>
    </button>
  );
};

export { BasketCount };
