import React from 'react';
import {
  CategoriesHeader,
  SecondaryButton,
  LoyaltyBanner,
} from '@fingermarkglobal/cringer.components';

import { ArrowButton } from '../arrow-button';

const Header = ({
  title = null,
  previousAction = null,
  allowPreviousAction = true,
  className = '',
  showCategoriesHeader = false,
  showMenuAction = true,
  menuText = 'Menu',
  menuAction = () => {},
  t = () => {},
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  computedAction = () => {},
  showLoyaltyBanner = false,
} = {}) => {
  return (
    <div data-test="header" className={`flex flex-col ${className}`}>
      {showCategoriesHeader && <CategoriesHeader />}
      {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
      <div className="px-8 py-16 font-bold uppercase text-9xl font-header">{title}</div>
      <div className="flex items-center justify-between h-24 px-8 py-6 bg-white bg-accessory min-h-24">
        <div className="flex w-full">
          {showMenuAction && (
            <SecondaryButton onClick={menuAction} data-test="menu-button">
              {menuText}
            </SecondaryButton>
          )}
          {!!previousAction && (
            <ArrowButton
              data-test="arrow-back-button"
              disabled={!allowPreviousAction}
              onClick={previousAction}
              className="border-none"
              iconReverse={false}
            >
              {t('menuPreviousAction')}
            </ArrowButton>
          )}
          <div className="flex justify-end w-full mr-4">
            {isCouponEnabled && showCouponButton && (
              <SecondaryButton
                className="ml-8 text-4xl font-bold"
                onClick={couponAction}
                data-test="enter-offer-button"
              >
                {couponText}
              </SecondaryButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Header };
