import React from 'react';

import ArrowWhite from '../../../assets/images/Double-arrow-white-icon.svg';
import ArrowBlack from '../../../assets/images/Double-arrow-black-icon.svg';

import { IconButton } from '@fingermarkglobal/cringer.components';

const ArrowButton = ({
  className = '',
  onClick,
  disabled = false,
  children,
  large = false,
  iconReverse = true,
  'data-test': dataTest = 'button-secondary',
} = {}) => {
  const dynamicStyle = iconReverse ? '' : 'transform rotate-180';

  const CustomIcon = ({ className = '' } = {}) =>
    iconReverse ? (
      <ArrowWhite className={`self-center ${dynamicStyle} ${className}`} />
    ) : (
      <ArrowBlack className={`self-center ${dynamicStyle} ${className}`} />
    );

  return (
    <IconButton
      data-test={dataTest}
      className={className}
      onClick={onClick}
      disabled={disabled}
      children={children}
      large={large}
      Icon={CustomIcon}
      iconReverse={iconReverse}
    />
  );
};

export { ArrowButton };
