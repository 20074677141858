import React from 'react';

import {
  PaymentMethods,
  ImageButton,
  BannerWarning,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import TBIcon from '../../../assets/images/Taco-Bell-symbol-logo.png';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackground from '../../../assets/images/background-home.png';

const PrimaryHome = ({
  t = () => {},
  card,
  cash,
  icons,
  healthMsg = true,
  className = '',
  primaryAction,
  secondaryAction,
  hasBothActionButtons,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const showingBanner = healthMsg || showTermsAndConditionsButton;

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackground,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className={`z-10 flex flex-col justify-between items-center h-full w-full overflow-y-hidden ${className}`}
      >
        <div
          data-test="home-upper"
          className={`flex flex-col justify-center items-center w-full space-y-16 ${
            accessibilityEnabled ? 'mt-64' : ''
          }`}
        >
          <div
            className={`flex flex-col justify-center items-center w-full mb-16 mt-12 ${
              !accessibilityEnabled ? 'my-32' : ''
            }`}
          >
            <img className={`max-w-logo`} src={TBIcon} data-test="home-banner-logo" />
          </div>

          {!accessibilityEnabled && (
            <div className="flex flex-col items-center justify-center my-5 leading-none">
              <p
                data-test="home-subtitle"
                className={`mb-16 -mt-24 tracking-wide text-white ${
                  showingBanner ? 'text-11xl' : 'text-18xl'
                }  font-secondary`}
              >
                {t('homeSubtitle')}
              </p>

              <p
                data-test="home-title"
                className={`mt-6 text-center text-white ${
                  showingBanner ? 'text-11xl' : 'text-18xl'
                } font-primary`}
              >
                {t('homeTitle')}
              </p>
            </div>
          )}

          <div
            data-test="home-services"
            className={`${
              hasBothActionButtons ? 'grid grid-cols-2 gap-16 w-4/5' : 'justify-center w-2/5'
            }  ${accessibilityEnabled ? 'pt-24' : ''}`}
          >
            {!!secondaryAction && (
              <ImageButton
                dataTest="button-secondary"
                className={`bg-eat-in ${hasBothActionButtons ? '' : 'important-h-24'}`}
                onClick={secondaryAction}
              />
            )}
            {!!primaryAction && (
              <ImageButton
                dataTest="button-primary"
                className={`bg-take-away ${hasBothActionButtons ? '' : 'important-h-24'}`}
                onClick={primaryAction}
              />
            )}
          </div>
          {!accessibilityEnabled && showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              className={`w-4/5`}
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {!accessibilityEnabled && healthMsg && (
            <BannerWarning t={t} width="109" image={WashHandsImage} className={`w-4/5`} />
          )}
        </div>

        <PaymentMethods
          t={t}
          card={card}
          cash={cash}
          icons={icons}
          enableIcons
          className={`w-full flex flex-row flex-wrap align-middle font-medium mb-12 text-4xl justify-evenly text-center text-white ${
            !accessibilityEnabled ? 'mb-32 py-6' : ''
          }`}
        />
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
